<template>
  <div style="display: inline-block">
    <b-button
      variant="info"
      class="mr-2 confirm-delivery"
      :disabled="disabled"
      @click="onShowModal()"
      >Add Delivery
      <i class="far fa-plus-square"></i>
      <!-- <b-icon icon="folder-check" aria-hidden="true"></b-icon> -->
    </b-button>
    <!-- @hide="modelHide" -->
    <b-modal
      v-model="showModal"
      centered
      no-close-on-backdrop
      scrollable
      title="Add Delivery"
      id="void_target"
      ref="modal"
    >
      <b-overlay :show="modalloading">
        <p>Please enter the delivery information.</p>
        <b-row style="padding-bottom: 150px">
          <b-col md="12" class="my-1">
            <b-form-group
              label="Logistics"
              label-for="logistics"
              class="logistics required"
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <b-input-group>
                <sv-select
                  v-model="logistics"
                  :placeholder="$t('page.please_choose')"
                  :options="options.logistics"
                  :reduce="options => options.value"
                  :selectable="options => !options.selectable"
                  label="label"
                >
                  <template
                    slot="option"
                    slot-scope="option"
                    v-if="option.index"
                  >
                  </template>
                </sv-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="12" class="my-1" v-if="logistics != 'pick_up'">
            <b-form-group
              label="Tracking No."
              label-for="tracking_no"
              class="required"
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <b-input-group>
                <b-form-input
                  v-model="tracking_no"
                  type="text"
                  id="tracking_no"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="12" class="my-1" v-if="logistics == 'pick_up'">
            <b-form-group
              label="Pick up by"
              label-for="pick_up_by"
              class="pick_up_by required"
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <b-input-group>
                <b-form-input
                  v-model="pick_up_by"
                  type="text"
                  id="pick_up_by"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <!-- <b-col md="12" class="my-1">
            <b-form-group
              label="Delivery Date"
              label-for="delivery_date"
              class="delivery_date"
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <b-input-group>
                <template v-slot:append>
                  <div
                    v-if="delivery_date != null && delivery_date != ''"
                    class="datepicker-x"
                    @click="initialTime(item, 'delivery_date')"
                  >
                    <b-icon-x font-scale="1.45"></b-icon-x>
                  </div>
                  <b-input-group-text>
                    <b-icon-calendar2-date></b-icon-calendar2-date>
                  </b-input-group-text>
                </template>
                <b-form-datepicker
                  v-model="delivery_date"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                  }"
                  :hide-header="true"
                  locale="de"
                ></b-form-datepicker>
              </b-input-group>
            </b-form-group>
          </b-col> -->

          <b-col md="12" class="my-1">
            <b-form-group
              label="Quantity"
              label-for="quantity"
              class="search-flex quantity"
              label-cols-sm="4"
              label-cols-lg="3"
              :class="{
                form_error: qty > maxQty
              }"
            >
              <b-input-group>
                <template v-slot:append>
                  <b-input-group-text> / {{ maxQty }} </b-input-group-text>
                </template>
                <b-form-input
                  v-model="qty"
                  type="number"
                  min="0"
                  :max="maxQty"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-overlay>
      <template v-slot:modal-footer="{ close }">
        <b-button
          variant="primary"
          @click="submit()"
          :disabled="submitDisabled"
        >
          Submit
        </b-button>
        <b-button variant="secondary" @click="close()"> Cancel </b-button>
      </template></b-modal
    >
  </div>
</template>

<script>
import apiBus from '@/common/apiBus/index';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';

export default {
  name: 'PurchaseoOverviewConfirmDelivery',

  data() {
    return {
      submitDisabled: false,
      modalloading: false,
      showModal: false,
      delivery_date: null,
      qty: 0,
      logistics: null,
      tracking_no: null,
      pick_up_by: null,
      options: {
        logistics: []
      }
    };
  },
  props: [
    'id',
    'disabled',
    // 'delivery_date',
    // 'logistics',
    // 'tracking_no',
    // 'pick_up_by',
    'purchase_note',
    'purchase_price',
    // 'purchase_date',
    // 'supplier_name',
    // 'delivery_to',
    'reserve_no',
    'type',
    'maxQty',
    'initDataFn'
  ],
  components: {},
  methods: {
    onShowModal() {
      console.log(this.logistics);
      this.showModal = true;
      this.submitDisabled = false;
      this.logistics = null;

      this.tracking_no = null;
      this.pick_up_by = null;
      this.delivery_date = null;
      this.qty = this.maxQty;

      apiBus.purchase
        .getExpressList()
        .then(data => {
          this.options.logistics = data.data.data;
        })
        .catch(error => {
          console.log(error);
        });
      this.showModal = true;
      this.submitDisabled = false;
      this.qty = this.maxQty;
    },
    submit() {
      var data;
      if (this.qty > this.maxQty) {
        this.$store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 5, //初始提示作用于定时器
          alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
          alertText: 'The quantity entered is out of limit.' // 提示内容
        });
        return false;
      }
      if (this.logistics == '' || this.logistics == null) {
        this.$store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 5, //初始提示作用于定时器
          alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
          alertText: 'Fields marked with * are required!' // 提示内容
        });
        return false;
      } else {
        if (
          (this.logistics != 'pick_up' &&
            (this.tracking_no == '' || this.tracking_no == null)) ||
          (this.logistics == 'pick_up' &&
            (this.pick_up_by == '' || this.pick_up_by == null))
        ) {
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Fields marked with * are required!' // 提示内容
          });
          return false;
        }
      }
      if (this.logistics == 'pick_up') {
        data = {
          // id
          purchase_overview_id: this.id,
          // 弹出层数据
          delivery_date: this.delivery_date,
          delivery_qty: this.qty,
          logistics: this.logistics,
          pick_up_by: this.pick_up_by,
          // 主体内容
          // purchase_date: this.purchase_date,
          reserve_no: this.reserve_no,
          // supplier: this.supplier_name,
          // delivery_address: this.delivery_to,
          purchase_price: this.purchase_price,
          purchase_note: this.purchase_note
        };
      } else {
        data = {
          // id
          purchase_overview_id: this.id,
          // 弹出层数据
          delivery_date: this.delivery_date,
          delivery_qty: this.qty,
          logistics: this.logistics,
          tracking_no: this.tracking_no,
          // 主体内容
          // purchase_date: this.purchase_date,
          reserve_no: this.reserve_no,
          // supplier: this.supplier_name,
          // delivery_address: this.delivery_to,
          purchase_price: this.purchase_price,
          purchase_note: this.purchase_note
        };
      }
      if (data.delivery_date == '' || data.delivery_date == null)
        data.delivery_date = this.format(new Date());

      console.log(data);

      this.modalloading = true;
      this.submitDisabled = true;
      apiBus.purchase
        .purchaseOverviewDelivery(data)
        .then(data => {
          console.log(data);
          this.modalloading = false;
          this.submitDisabled = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Successfully!' // 提示内容
          });
          this.showModal = false;
          this.initDataFn();
        })
        .catch(error => {
          console.log(error);
          this.modalloading = false;
          this.submitDisabled = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },
    initialTime(obj, name) {
      obj[name] = null;
    },
    modelHide() {
      if (document.getElementsByClassName('form_error').length > 0) {
        var scroll_top = 0;
        if (document.documentElement && document.documentElement.scrollTop) {
          scroll_top = document.documentElement.scrollTop;
        } else if (document.body) {
          scroll_top = document.body.scrollTop;
        }
        var divTop = document
          .getElementsByClassName('form_error')[0]
          .getBoundingClientRect().top;
        console.log(divTop);
        window.scrollTo(
          0,
          divTop + scroll_top - document.documentElement.clientHeight / 3
        );
      }
    },
    add0(m) {
      return m < 10 ? '0' + m : m;
    },
    format(shijianchuo) {
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      return y + '-' + this.add0(m) + '-' + this.add0(d);
    },
    init() {}
  },

  computed: {},
  watch: {
    'logistics.error'() {
      this.modelHide();
    }
  },
  mounted() {
    this.init();
  }
};
</script>
<style scoped>
.confirm-delivery {
  color: #fff;
}
#void_target .modal-body {
  padding-bottom: 150px;
}
</style>
